import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj28 = () => {
  return (
    <Layout>
      <SEO title="Anagrams" description="Awesome python project" />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-28">
          <div className="py-1-heading">
            <h1>Anagrams</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Anagrams"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              Cool project...
              <br />
              <br />
              Here, we have to find the anagrams.
              <br />
              Anagrams is a word formed by rearranging the letters of another
              word
              <br />
              Both word must have an authentic meaning.
              <br />
              No extra letters can be added.
              <br />
              <br />
              Example :- TAR ------ RAT
              <br />
              <br />
              I did it in 2 flavours :-
              <br /> *Using length and orders.
              <br />
              *Using string sorting.
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj28
